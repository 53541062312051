/**
 * Uploads files to the server along with metadata.
 * @param {File[]} files - An array of files to upload.
 * @param {Object} meta - Metadata object containing type, company, orderId, and user.
 * @returns {Promise<string[]>} - A promise that resolves to an array of URLs for the uploaded images.
 * @throws {string} - Throws an error if files are null or metadata is incomplete.
 */
export async function uploadPromise(files, meta) {
  if (!files) throw 'files cannot be null'
  if (!meta || !meta.type || !meta.company || !meta.orderId || !meta.user)
    throw 'incomplete metadata' + JSON.stringify(meta)

  if (typeof meta.orderId !== 'number') throw 'orderId must be a number'

  const formData = new FormData()
  formData.append('metaData', JSON.stringify(meta))

  for (let i = 0; i < files.length; i++) {
    formData.append(`file-${i}`, files[i], files[i].name || Date.now())
  }

  if (formData.length === 1) throw 'No files?'

  const response = await fetch(`${import.meta.env.VITE_API_URL}/api/OrderImage/images`, {
    method: 'POST',
    body: formData,
  })

  const result = await response.json()

  if (result.data.some(r => !r.success)) throw result.errorMessage

  return result.data.map(r => `${import.meta.env.VITE_IMAGE_CDN_URL}/${r.imageName}`)
}

/**
 * Converts a data URI to a Blob object.
 * @param {string} dataURI - The data URI to convert.
 * @returns {Blob} The Blob object representing the converted data URI.
 */
export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}
