export const PLANNED = 10
export const NOTIFIED = 20
export const ON_BOARD = 50
export const RELOCATED = 80
export const RELOCATED_ON_BOARD = 85
// parked at facility
export const RECEIVED = 100
// dumped at facility
export const PROCESSING = 150
// digitally processed and ready for invoicing
export const PROCESSED = 200
// digital flow does not exist yet, but order is ready for invoicing anyways
export const FAKE_PROCESSED = 201
export const INVOICED = 300

export const statuses = {
  PLANNED,
  NOTIFIED,
  ON_BOARD,
  RELOCATED,
  RELOCATED_ON_BOARD,
  RECEIVED,
  PROCESSING,
  PROCESSED,
  FAKE_PROCESSED,
  INVOICED,
}
