import { Settings } from 'luxon'
import { DateTime } from 'luxon-business-days'

export function isThirteenthDay(current) {
  const matchesMonth = current.month === 1
  const matchesDay = current.day === 6

  return matchesMonth && matchesDay
}

export function isFirstOfMay(current) {
  const matchesMonth = current.month === 3
  const matchesDay = current.day === 1

  return matchesMonth && matchesDay
}

export function isNationalDay(current) {
  const matchesMonth = current.month === 6
  const matchesDay = current.day === 6

  return matchesMonth && matchesDay
}

export function setup() {
  Settings.defaultLocale = 'sv'
  Settings.defaultZone = 'utc'

  const dt = DateTime.local()
  const { availableHolidayMatchers } = dt

  dt.setupBusiness({
    holidayMatchers: [
      availableHolidayMatchers.isNewYearsDay,
      availableHolidayMatchers.isEasterDay,
      isThirteenthDay,
      isFirstOfMay,
      isNationalDay,
      availableHolidayMatchers.isChristmasDay,
    ],
  })
}
