import { isDev } from './env'

export function crane(o) {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase().includes('kran'))
}

export function faBags(o) {
  return Boolean(o.orderCategory.orderCategoryName.toLowerCase() === 'farligt avfall')
}

export function faBagsNotInProd(o, IsFaRollout) {
  return faBags(o) && (IsFaRollout || isDev())
}

export function receptacle(o) {
  return Boolean(
    o.orderCategory.orderCategoryName.toLowerCase().includes('kärl') &&
      o.receptacleQuantities &&
      o.receptacleQuantities.length > 0
  )
}

export function bagDelivery(o) {
  return Boolean(
    o.orderCategory.orderCategoryName.toLowerCase().includes('lev') &&
      o.bagDeliveryQuantities.prefix
  )
}

export function LD(o) {
  const category = o.orderCategory.orderCategoryName.toLowerCase()
  return Boolean(category === 'ld' && o.bookingActionType)
}

export function LVX(o) {
  const category = o.orderCategory.orderCategoryName.toLowerCase()
  return Boolean(category === 'lvx' && o.bookingActionType)
}

export function head(o) {
  return Boolean(!o.subOrderCode && o.subOrderCodes && o.subOrderCodes.length > 0)
}

export function sub(o) {
  return Boolean(o.subOrderCode && o.parentOrderId > 0)
}
