import { doc, getDoc, setDoc } from 'firebase/firestore'
import { useFirestore } from 'vuefire'

import { DateTime } from 'luxon'

import { PLANNED, RECEIVED } from '@/utils/status.constants'

const db = useFirestore()

// We need this function to exist until RX impliments message bus
/**
 * Ensures that the given order is up to date in the database.
 * @param {Object} order - The order to ensure is up to date.
 * @param {Object} store - The store object containing the current user's information.
 * @returns {Object} - The updated order object.
 * @throws {Error} - If store or order are missing.
 */
export async function ensureUpToDateEntry(order, store) {
  if (!store) throw new Error('Missing store')
  if (!order) throw new Error('Missing order')

  if (!store.currentResource)
    throw new Error('How is this possible, we should have a resource by now')

  const copy = JSON.parse(JSON.stringify(order))

  const docName = copy.subOrderCode || copy.id
  const entryRef = doc(db, 'entries', String(docName))
  const docSnap = await getDoc(entryRef)

  // we need this to be able to sort by date in other queries
  copy.startEpoch = DateTime.fromISO(copy.startDateTime + 'Z').toMillis()

  if (docSnap.exists()) {
    const data = docSnap.data()

    // the driver is the same, do nothing
    if (data.driver && data.driver.email === store.username) return copy

    let changes = null

    // we dont want admins to be labeled as drivers
    if (!store.IsAdmin && data.statusValue < RECEIVED) {
      changes = {
        driver: {
          vehicle: store.currentResource,
          name: store.currentUserSpaced,
          email: store.username,
        },
      }
    }

    if (!data.notify || !data.notify.INITIALIZED) {
      if (!changes) changes = {}
      changes.notify = { INITIALIZED: DateTime.utc().toMillis() }
    }

    if (changes) {
      setDoc(entryRef, changes, { merge: true })
    }
  } else {
    await setDoc(entryRef, {
      order: copy,
      id: docName,
      status: 'Planned',
      statusValue: PLANNED,
      facility: {
        isContainer: Boolean(copy.bookingId && copy.bookingActionType),
        previouslyParked: false,
        name: null,
        email: null,
      },
      driver: {
        extraVehicleMoves: 0,
        vehicle: store.currentResource,
        name: store.currentUserSpaced,
        email: store.username,
      },
      notify: {
        INITIALIZED: DateTime.utc().toMillis(),
        ON_OUR_WAY: null,
        ON_BOARD: null,
        RELOCATED: null,
        RECEIVED: null,
        PROCESSING: null,
        PROCESSED: null,
        INVOICED: null,
      },
    })

    // lets give firebase a chance to catch up
    await new Promise(resolve => setTimeout(resolve, 500))
  }

  return copy
}
