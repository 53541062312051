import { DirectiveBinding } from 'vue'

import Trans from '@/i18n/translation'

export default {
  mounted: (el: HTMLElement, binding: DirectiveBinding) => {
    if (!el.classList.contains('hyphens-auto')) el.classList.add('hyphens-auto')

    if (!el.getAttribute('lang'))
      el.setAttribute('lang', binding.arg ? binding.arg : Trans.currentLocale)
  },
}
