export const SORTERA = 'sortera'
export const SORTERA_GOTEBORG = 'sorteragoteborg'
export const SORTERA_STOCKHOLM = 'sorterastockholm'
export const BIG_BAG = 'bigbag'
export const SORTERA_MALMO = 'sorteramalmo'
export const AKERISACKEN = 'akerisacken'
export const SORTERA_OREBRO = 'sorteraorebro'

export const COMPANIES = [
  { id: 1, code: SORTERA_GOTEBORG, name: 'Sortera Göteborg' },
  { id: 2, code: SORTERA_STOCKHOLM, name: 'Sortera Stockholm' },
  { id: 3, code: BIG_BAG, name: 'BigBag' },
  { id: 4, code: SORTERA_MALMO, name: 'Sortera Malmö' },
  { id: 5, code: AKERISACKEN, name: 'Åkerisäcken' },
  { id: 6, code: SORTERA_OREBRO, name: 'Sortera Örebro' },
]
