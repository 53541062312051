<script setup>
const props = defineProps({
  id: {
    required: true,
    type: String,
  },
  title: {
    default: null,
    type: String,
  },
  fullHeight: {
    default: false,
    type: Boolean,
  },
  mobileLike: {
    default: true,
    type: Boolean,
  },
})
</script>

<template>
  <dialog :id="props.id" class="modal" :class="{ 'modal-bottom sm:modal-middle': mobileLike }">
    <form
      class="modal-box bg-light-fore dark:bg-dark-fore"
      :class="{ 'h-full w-full': props.fullHeight }"
      method="dialog"
      @submit.prevent=""
    >
      <div class="flex h-full flex-col gap-4">
        <div class="flex items-center justify-between">
          <h3>
            <template v-if="props.title">
              {{ props.title }}
            </template>
          </h3>
          <div class="flex items-center gap-2">
            <span class="text-sm">{{ $t('general.close') }}</span>
            <form method="dialog">
              <button class="btn btn-circle btn-ghost btn-sm">
                <BaseIcon class="h-5" name="close" />
              </button>
            </form>
          </div>
        </div>
        <div class="flex flex-1 flex-col gap-6">
          <slot />
        </div>
      </div>
    </form>
    <form class="modal-backdrop" method="dialog">
      <button>x</button>
    </form>
  </dialog>
</template>
