<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: null,
    validator: value => ['success', 'error', 'warning', 'info'].includes(value),
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
})

const iconSymbol = computed(() => {
  switch (props.type) {
    case 'success':
      return 'circle-check'
    case 'error':
      return 'hexagon-exclamation'
    case 'warning':
      return 'exclamation-triangle'
    case 'info':
      return 'circle-info'
    default:
      return null
  }
})
</script>

<template>
  <div
    class="flex flex-1 items-stretch gap-2 rounded-lg border p-4"
    :class="{
      'border-success bg-success/20 text-success-content dark:text-success':
        props.type === 'success',
      'border-error bg-error/20 text-error-content': props.type === 'error',
      'border-warning bg-warning/20 text-warning-content dark:text-warning':
        props.type === 'warning',
      'border-info bg-info/20': props.type === 'info',
      'border-gray-400 bg-gray-400/20': !props.type,
    }"
  >
    <div v-if="props.showIcon && iconSymbol" class="flex items-start p-1">
      <BaseIcon
        class="h-4 w-4"
        :class="{
          '-mt-1': props.title, // pls fix, this is a hack
        }"
        :name="iconSymbol"
      />
    </div>
    <div class="flex flex-col gap-1">
      <div v-if="props.title" class="text-xs font-semibold">
        {{ props.title }}
      </div>
      <div class="flex h-full items-center gap-2 text-sm">
        <slot />
      </div>
    </div>
  </div>
</template>
