/**
 * Retrieves the saved state from local storage for the given key.
 * @param {string} key - The key to retrieve the saved state for.
 * @returns {any} - The saved state for the given key.
 */
export function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

/**
 * Saves the given payload to local storage under the specified key.
 * @param {string} key - The key to use for storing the payload in local storage.
 * @param {any} payload - The data to store in local storage.
 */
export function saveState(key, payload) {
  return window.localStorage.setItem(key, JSON.stringify(payload))
}
