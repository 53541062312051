<script setup>
const props = defineProps({
  isDangerous: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: null,
  },
})
</script>

<template>
  <div
    v-hyphen:sv
    class="flex items-center gap-1 rounded-2xl border p-1 px-2"
    :class="{
      'border-error bg-error/5 text-error': props.isDangerous,
      'border-gray-400 dark:border-base-content': !props.isDangerous,
    }"
  >
    <template v-if="count">
      {{ count }}
      <span class="font-mono text-xs opacity-60">x</span>
    </template>
    <slot />
    <BaseIcon v-if="props.isDangerous" class="h-3 text-error" name="rhombus" />
  </div>
</template>
