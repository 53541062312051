<script>
import NavBar from '@/components/nav-bar.vue'

export default {
  components: { NavBar },
  data() {
    return {
      navbarTitle: '',
    }
  },
  computed: {
    shouldShowBackBtn() {
      return this.navbarTitle.toLowerCase().startsWith('#')
    },
  },
}
</script>

<template>
  <div class="flex min-h-screen w-full flex-1 flex-col">
    <NavBar :showBackBtn="shouldShowBackBtn" :title="navbarTitle" />
    <RouterView :key="$route.fullPath" class="flex-1" @title-change="navbarTitle = $event" />
  </div>
</template>
