<script setup>
const props = defineProps({
  iconName: {
    default: null,
    type: String,
  },
  label: {
    default: null,
    type: String,
  },
  hideIcon: {
    default: false,
    type: Boolean,
  },
})
</script>

<template>
  <div class="flex">
    <div v-if="!hideIcon" class="flex w-12 items-center justify-center px-4">
      <BaseIcon
        v-if="props.iconName"
        class="text-2xl"
        :class="{
          'text-error': props.iconName === 'rhombus',
        }"
        :name="props.iconName"
      />
    </div>
    <div class="flex flex-col">
      <label v-if="props.label" class="text-xs opacity-90">{{ props.label }}</label>
      <slot />
    </div>
  </div>
</template>
