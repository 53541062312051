import { watch } from 'vue'

import * as Sentry from '@sentry/browser'

import { useAuth } from '@/composables/auth.ts'
import router from '@/router'

const sentry = {
  install(app) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      trackComponents: true,
      environment: import.meta.env.VITE_ENVIRONMENT_NAME,
      hooks: ['mount', 'update', 'destroy'],
      tracePropagationTargets: ['driver.sortera.org', 'dv.sortera.org', /^\//],
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      ],
      tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
      // You may want to set the sample rate it to 100% while in development
      // and then sample at a lower rate in production.
      replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSIONS_SAMPLE_RATE),
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
      ignoreErrors: [],
    })

    watch(
      useAuth().account,
      (currentAccount, prevAccount) => {
        const currentMail = currentAccount?.email ?? null
        const prevMail = prevAccount?.email ?? null
        if (currentMail !== prevMail) {
          Sentry.setUser({
            email: currentMail ?? undefined,
            username: currentAccount?.name,
            id: currentAccount?.accountId,
          })
        }
      },
      { immediate: true, deep: true }
    )
  },
}

export default sentry
